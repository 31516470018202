import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { useLocatedPage } from '../../hooks/use-located-page'
import { Layout } from '../../layouts'
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient()

const Page = ({ data, pageContext, path }) => {
  useLocatedPage(path.slice(path.lastIndexOf('/') + 1))

  return (
    <QueryClientProvider client={queryClient}>
        <Layout pageTitle={data.mdx.frontmatter.title} country={pageContext.country} language={pageContext.language}>
            <MDXRenderer>
                {data.mdx.body}
            </MDXRenderer>
        </Layout>
    </QueryClientProvider>
  )
}

export const query = graphql`
  query DynamicPageBySlugAndCountry($slug: String, $country: String) {
    mdx(frontmatter: {slug: {eq: $slug}, country: {eq: $country}}) {
      frontmatter {
        title
      }
      body
    }
  }
`

export default Page
